<template>
  <div>
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="addFormRulue"
        label-width="110px"
      >
        <el-form-item label="姓名" prop="realName">
          <el-col :span="11">
            <el-input
              v-model="form.realName"
              :maxlength="100"
              show-word-limit
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="简介" prop="desc">
          <el-col :span="11">
            <el-input
              v-model="form.desc"
              :maxlength="300"
              show-word-limit
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="公司对外名称" prop="company">
          <el-col :span="11">
            <el-input
              v-model="form.company"
              :maxlength="50"
              show-word-limit
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="职务对外名称" prop="job">
          <el-col :span="11">
            <el-input v-model="form.job" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="手机号">
          <el-col :span="11">
            <el-input v-model="form.phone" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-col :span="11">
            <el-input v-model="form.email" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-col :span="11">
            <el-input v-model="form.remark" @change=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="小助手" prop="waId">
          <el-select v-model="form.waId" multiple :multiple-limit="3">
            <el-option
              :label="item.name"
              :value="item.id + ''"
              v-for="(item, index) in $store.state.NormalList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动" prop="activityId">
          <el-select
            v-model="form.activityId"
            multiple
            :multiple-limit="3"
            filterable
            remote
            reserve-keyword
            placeholder="请输入活动名称模糊查询"
            :remote-method="remoteMethod"
            :loading="loading"
            style="width: 480px;"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="嘉宾宣传照" prop="avatar">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
            :data="action_1"
          >
            <img
              v-if="form.avatar"
              :src="baseurl + yu + form.avatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="资料打包">
          <el-upload
            ref="pdf"
            class="upload-demo"
            :on-progress="beforeAvatarUpload"
            :on-remove="word"
            :action="this.$store.getters.imgUrl"
            accept=".zip , .rar"
            :headers="header"
            :on-success="handleAvatarSuccess"
            :data="action"
            :on-preview="load"
            :limit="1"
            :show-file-list="true"
          >
            <el-button size="small" type="primary" v-if="pdfstaus"
              >点击上传</el-button
            >
            <button
              size="small"
              type="primary"
              :disabled="true"
              v-else
              class="lod"
            >
              加载中
            </button>
            <div class="el-upload__tip waring" slot="tip">
              请上传zip，rar文件（包含宣传物料，logo源文件等内容
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="add" v-if="!id">{{
            btn ? '确 定' : 'loading..'
          }}</el-button>
          <el-button type="primary" @click="editadd" v-if="id">{{
            btn ? '修 改' : 'loading..'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import quillConfig from '../../quill'
import rules from '../../utils/rules.js'
export default {
  data() {
    return {
      quillOption: quillConfig,
      keysome: '',
      sd: [],
      loading: false,
      options: [],
      form: {
        realName: '',
        desc: '',
        company: '',
        job: '',
        phone: '',
        email: '',
        activityId: [],
        avatar: '',
        document: '',
        remark: '',
        waId: []
      },
      pdfstaus: true,
      addFormRulue: {
        realName: [rules.req('请输入姓名')],
        desc: [rules.req('请输入简介')],
        company: [rules.req('请输入公司对外名称')],
        job: [rules.req('请输入职务对外')],
        phone: [rules.req('请输入手机号')],
        email: [rules.req('请输入邮箱')]
      },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      btn: true,
      action: {
        action: 'speaker',
        type: 'document'
      },
      action_1: {
        action: 'speaker'
      },
      area: [],
      header: {
        Token: sessionStorage.getItem('token')
      },
      id: null
    }
  },
  methods: {
    word() {
      this.pdfstaus = true
    },
    beforeAvatarUpload() {
      this.pdfstaus = false
    },
    remoteMethod(qu) {
      if (qu != '') {
        this.loading = true
        this.$http
          .get('/admin/Activity/getList', {
            params: {
              page: 1,
              pageSize: 50,
              title: qu,
              eventDate: '',
              publishDate: '',
              publishStatus: '',
              checkStatus: '',
              acId: '',
              order: ''
            }
          })
          .then(({ data: res }) => {
            this.loading = false
            if (res.errorCode == 200) {
              console.log(res.data)
              this.options = []
              res.data.list.forEach(item => {
                this.options.push({ value: item.id, label: item.title })
              })
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    getfrom() {
      if (this.$route.query.id) {
        var id = this.$route.query.id
        this.id = id
        this.$http
          .get('/admin/UserSpeaker/getById?id=' + id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              // console.log(res);
              this.form = {
                ...res.data,
                activityId:
                  !!res.data.activityId && res.data.activityId.split(','),
                waId: !!res.data.waId && res.data.waId.split(',')
              }
              var eds = res.data.activityId.split(',')
              var act = res.data.activityName.split(',')
              eds.forEach((item, n) => {
                this.options.push({ value: item, label: act[n] })
              })
              console.log(this.form)
              this.form.id = id * 1
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },

    load() {
      // console.log(file);
      location.href = this.baseurl + this.yu + this.form.document
    },
    add() {
      this.$refs.form.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/UserSpeaker/add', {
              ...this.form,
              activityId: this.form.activityId.join(','),
              waId: this.form.waId.toString()
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success('操作成功')
                this.$router.push('/user_speaker')
              } else {
                this.$message.error(res.message)
              }
              this.btn = true
              // if(res.)
            })
        }
        this.btn = false
      })
    },
    editadd() {
      this.$refs.form.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/UserSpeaker/edit', {
              ...this.form,
              activityId: this.form.activityId.join(','),
              waId: this.form.waId.toString()
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.$router.push('/user_speaker')
              } else {
                this.$message.error(res.message)
              }
              this.btn = true
            })
        }
        this.btn = false
      })
    },
    handleAvatarSuccess_1(res) {
      if (res.errorCode == 200) {
        this.form.avatar = res.data.url
        this.$forceUpdate()
      } else {
        this.$message.error(res.message)
      }
    },
    handleAvatarSuccess(res, file) {
      this.form.document = ''
      if (res.errorCode == 200) {
        this.form.document = res.data.url
        this.$forceUpdate()
        // this.form=[...this.form]
      } else {
        this.$message.error(res.message)
        this.$refs.pdf.clearFiles()
      }
      this.pdfstaus = true
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeUpload() {
      if (this.pdfstaus) {
        return false
      }
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
          console.log(this.area)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  },

  created() {
    this.getea()
    this.getfrom()
  },
  mounted() {
    // console.log(this.area);
    sessionStorage.setItem('action', 'report')
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  height: 178px;
  display: block;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
.lod {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: not-allowed;
  background-color: #fda400;
  // background: #fff;
  border: 1px solid #dcdfe6;
  color: white;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 13px;
  border-radius: 4px;
}
</style>
